import React, { useContext, useEffect, useState } from "react";
import moment from "moment";

import { makeStyles } from "@material-ui/core/styles";
import {
  Divider,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableSortLabel,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  Toolbar,
  Typography
} from "@material-ui/core";
import { red } from "@material-ui/core/colors";

import { SearchContext } from "./index";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  container: {
    maxHeight: 1000
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 750
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  },
  salesHeader: {
    padding: theme.spacing(2, 0)
  }
}));

const desc = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

const stableSort = (array, cmp) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
};

const getSorting = (order, orderBy) => {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
};

const headCells = [
  { id: "sale_type", numeric: false, label: "Sale Type" },
  { id: "acceptance_date", numeric: false, label: "Acceptance Date" },
  { id: "cos_agreement_date", numeric: false, label: "COS Agreement Date" },
  { id: "district", numeric: false, label: "District" },
  { id: "town", numeric: false, label: "Town/Village" },
  { id: "quarter", numeric: false, label: "Quarter" },
  { id: "declared_price", numeric: true, label: "Declared Price" },
  { id: "accepted_price", numeric: true, label: "Accepted Price" },
  { id: "block", numeric: false, label: "Block" },
  { id: "registration_no", numeric: false, label: "Reg. #" },
  { id: "sheet", numeric: false, label: "Sheet" },
  { id: "plan", numeric: false, label: "Plan" },
  { id: "parcel_no", numeric: false, label: "Plot #" },
  { id: "parcel_ext", numeric: true, label: "Plot Size (m²)" },
  { id: "dlo_file", numeric: false, label: "DLO File" },
  { id: "dlo_file_no", numeric: true, label: "DLO File No" },
  { id: "dlo_file_year", numeric: true, label: "DLO File Year" },
  { id: "fiscal_property_type", numeric: false, label: "Fiscal Property Type" },
  { id: "share_numerator", numeric: true, label: "Share Numerator" },
  { id: "share_denominator", numeric: true, label: "Share Denominator" },
  { id: "remark1", numeric: false, label: "Remark 1" },
  { id: "remark2", numeric: false, label: "Remark 2" },
  { id: "sales_remark", numeric: false, label: "Sales Remark" },
  { id: "main_sbp_cat", numeric: false, label: "Main Sbp Cat #" },
  { id: "main_sbp_kind", numeric: false, label: "Main Sbp Kind" },
  { id: "status1", numeric: false, label: "Status 1" },
  { id: "enclosed_ext", numeric: true, label: "Enclosed Area (m²)" },
  { id: "covered_ext", numeric: true, label: "Covered Area (m²)" },
  { id: "uncovered_ext", numeric: true, label: "Uncovered Area (m²)" },
  { id: "sec_sbp_cat", numeric: false, label: "Sec Sbp Cat #" },
  { id: "sec_sbp_kind", numeric: false, label: "Sec Sbp Kind" },
  { id: "status2", numeric: false, label: "Status 2" }
];

const Sales = props => {
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("date");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [salesTotal, setSalesTotal] = useState();

  const searchContext = useContext(SearchContext);
  const { sales } = searchContext;

  useEffect(() => {
    const totalSum = sales.reduce((total, item) => {
      if (!item.accepted_price) return total;
      return total + parseInt(item.accepted_price);
    }, 0);
    setSalesTotal(totalSum);
  }, [sales]);

  const classes = useStyles();

  const handleRequestSort = (e, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const createSortHandler = property => event => {
    handleRequestSort(event, property);
  };

  return (
    <div className={classes.root}>
      <Divider className={classes.divider} />

      <Paper className={classes.paper}>
        <Toolbar>
          {salesTotal > 0 ? (
            <div className={classes.salesHeader}>
              <Typography variant="h6">Sales history</Typography>
              <Typography
                variant="subtitle1"
                display="block"
                style={{ color: red[500] }}
              >
                Total sales €{salesTotal.toLocaleString()}
              </Typography>
            </div>
          ) : (
            <Typography variant="h6">Sales history</Typography>
          )}
        </Toolbar>
        <TableContainer className={classes.container}>
          <Table className={classes.table} stickyHeader>
            <TableHead>
              <TableRow>
                {headCells.map(headCell => (
                  <TableCell
                    key={headCell.id}
                    align={headCell.numeric ? "right" : "left"}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : "asc"}
                      onClick={createSortHandler(headCell.id)}
                    >
                      {headCell.label}
                      {orderBy === headCell.id ? (
                        <span className={classes.visuallyHidden}>
                          {order === "desc"
                            ? "sorted descending"
                            : "sorted ascending"}
                        </span>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {stableSort(sales, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <TableRow hover key={index}>
                    <TableCell>{row.sale_type}</TableCell>
                    <TableCell>
                      {row.acceptance_date
                        ? moment(row.acceptance_date).format("DD/MM/YYYY")
                        : ""}
                    </TableCell>
                    <TableCell>
                      {row.cos_agreement_date
                        ? moment(row.cos_agreement_date).format("DD/MM/YYYY")
                        : ""}
                    </TableCell>
                    <TableCell>{row.district}</TableCell>
                    <TableCell>{row.town}</TableCell>
                    <TableCell>{row.quarter}</TableCell>
                    <TableCell align="right">{row.declared_price}</TableCell>
                    <TableCell align="right">{row.accepted_price}</TableCell>
                    <TableCell>{row.block}</TableCell>
                    <TableCell>{row.registration_no}</TableCell>
                    <TableCell>{row.sheet}</TableCell>
                    <TableCell>{row.plan}</TableCell>
                    <TableCell>{row.parcel_no}</TableCell>
                    <TableCell align="right">{row.parcel_ext}</TableCell>
                    <TableCell>{row.dlo_file}</TableCell>
                    <TableCell align="right">{row.dlo_file_no}</TableCell>
                    <TableCell align="right">{row.dlo_file_year}</TableCell>
                    <TableCell>{row.fiscal_property_type}</TableCell>
                    <TableCell>{row.share_numerator}</TableCell>
                    <TableCell>{row.share_denominator}</TableCell>
                    <TableCell>{row.remark1}</TableCell>
                    <TableCell>{row.remark2}</TableCell>
                    <TableCell>{row.sales_remark}</TableCell>
                    <TableCell>{row.main_sbp_cat}</TableCell>
                    <TableCell>{row.main_sbp_kind}</TableCell>
                    <TableCell>{row.status1}</TableCell>
                    <TableCell>{row.enclosed_ext}</TableCell>
                    <TableCell>{row.covered_ext}</TableCell>
                    <TableCell>{row.uncovered_ext}</TableCell>
                    <TableCell>{row.sec_sbp_cat}</TableCell>
                    <TableCell>{row.seb_sbp_kind}</TableCell>
                    <TableCell>{row.status2}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 15, 30]}
          component="div"
          count={sales.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
};

export default Sales;
