import React, { useRef, useState, useEffect } from "react";
import { API } from "aws-amplify";
import moment from "moment";

import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  Chip,
  Typography,
  Box
} from "@material-ui/core";
import {
  InsertDriveFile,
  ClearAll,
  Cached,
  Check,
  Close
} from "@material-ui/icons";

import File from "./File";

const useStyles = makeStyles(theme => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightBold
  },
  button: {
    margin: theme.spacing(2, 1, 0, 0)
  },
  files: {
    maxWidth: 600,
    backgroundColor: theme.palette.background.paper,
    padding: 0
  },
  fileUpload: {
    display: "none"
  },
  status: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    margin: theme.spacing(2, 0),
    "&> *": {
      margin: theme.spacing(0, 2, 2, 0)
    }
  },
  success: {
    backgroundColor: theme.palette.success.main,
    color: "white",
    "& > *": {
      color: "white"
    }
  },
  error: {
    backgroundColor: theme.palette.error.main,
    color: "white",
    "& > *": {
      color: "white"
    }
  },
  running: {
    backgroundColor: theme.palette.info.main,
    color: "white",
    "& > *": {
      color: "white"
    }
  }
}));

const UploadData = props => {
  const [files, setFiles] = useState([]);
  const [status, setStatus] = useState();
  const [lastRun, setLastRun] = useState();
  const [refreshing, setRefreshing] = useState(false);
  const fileRef = useRef(null);

  const classes = useStyles();

  const refreshStatus = async () => {
    const results = await API.get("backend", "/status");
    const { status, started_at } = results;
    setLastRun(moment(started_at).format("DD/MM/YYYY h:mm:ss A"));
    setStatus(status);
  };

  const onRefresh = async () => {
    if (refreshing) return;

    setRefreshing(true);
    try {
      await API.post("backend", "/refresh");
    } catch (err) {
      console.log(err);
      alert("Failed to initiate refresh of database");
    } finally {
      setStatus("RUNNING");
      setLastRun(null);
      refreshStatus();
      setRefreshing(false);
    }
  };

  useEffect(() => {
    refreshStatus();
    const interval = setInterval(() => {
      refreshStatus();
    }, 60000);
    return () => clearInterval(interval);
  }, []);

  const statusMap = {
    SUCCEEDED: {
      class: classes.success,
      icon: <Check />
    },
    RUNNING: {
      icon: <CircularProgress size={20} color="inherit" />
    },
    FAILED: {
      class: classes.error,
      icon: <Close />
    }
  };

  return (
    <div>
      <List className={classes.files}>
        {!files.length && (
          <ListItem>
            <ListItemText primary={<em>Upload one or more files below.</em>} />
          </ListItem>
        )}
        {files.map((file, i) => (
          <File file={file} key={i} />
        ))}
      </List>

      <Button
        variant="outlined"
        className={classes.button}
        startIcon={<ClearAll />}
        onClick={() => setFiles([])}
      >
        Clear files
      </Button>

      <input
        ref={fileRef}
        type="file"
        className={classes.fileUpload}
        multiple
        onChange={e => setFiles([...files, ...e.target.files])}
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      />

      <Button
        disableElevation
        variant="contained"
        color="primary"
        className={classes.button}
        startIcon={<InsertDriveFile />}
        onClick={() => fileRef.current.click()}
      >
        Upload files
      </Button>

      <Button
        disabled={
          !status ||
          refreshing ||
          ["RUNNING", "STARTING", "STOPPING"].includes(status)
        }
        disableElevation
        variant="contained"
        className={classes.button}
        startIcon={
          refreshing ? (
            <CircularProgress size={20} color="inherit" />
          ) : (
            <Cached />
          )
        }
        onClick={onRefresh}
      >
        {refreshing ? "Refreshing" : "Refresh"} database
      </Button>

      <Box mt={2}>
        <Typography>1. UNZIP THE FILES</Typography>
        <Typography>2. UPLOAD THE UNZIPPED FILES</Typography>
        <Typography>3. PRESS REFRESH</Typography>
        <Typography>It should take around 20 minutes to refresh.</Typography>
      </Box>

      <div className={classes.status}>
        <Typography>Last refresh status:</Typography>
        <>
          <Chip
            className={status in statusMap ? statusMap[status].class : null}
            disabled={
              !status ||
              refreshing ||
              ["RUNNING", "STARTING", "STOPPING"].includes(status)
            }
            icon={
              status ? (
                status in statusMap ? (
                  statusMap[status].icon
                ) : null
              ) : (
                <CircularProgress size={20} color="inherit" />
              )
            }
            label={status ? status : "Loading..."}
          />
          <Typography variant="caption">{lastRun}</Typography>
        </>
      </div>
    </div>
  );
};

export default UploadData;
