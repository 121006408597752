import React, { useState } from "react";

import { Fade } from "@material-ui/core";

import Search from "./Search";
import Sales from "./Sales";

export const SearchContext = React.createContext();

const SalesSearch = props => {
  const [sales, setSales] = useState([]);
  const [searching, setSearching] = useState(false);
  const [searched, setSearched] = useState(false);

  return (
    <SearchContext.Provider
      value={{
        sales,
        setSales,
        searching,
        setSearching,
        searched,
        setSearched
      }}
    >
      <Search />

      {searched && !searching && (
        <Fade>
          <Sales />
        </Fade>
      )}
    </SearchContext.Provider>
  );
};

export default SalesSearch;
