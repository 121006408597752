import React, { useState, useEffect } from "react";
import { API } from "aws-amplify";
import axios from "axios";

import {
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  Divider,
  CircularProgress
} from "@material-ui/core";
import { AttachFile, CheckCircle, Cancel } from "@material-ui/icons";
import { green, red } from "@material-ui/core/colors";

const File = ({ file }) => {
  const [loading, setLoading] = useState(true);
  const [parsed, setParsed] = useState(false);

  useEffect(() => {
    const request = async () => {
      // Make a request to upload ${file.name} to the S3 bucket
      const upload = await API.post("backend", "/upload", {
        body: { name: file.name }
      });
      // Get back the signed URL and fields required in the signed POST to S3
      const { url, fields } = upload;

      // Create a formData object and populate the fields
      const formData = new FormData();
      Object.keys(fields).forEach(key => {
        formData.append(key, fields[key]);
      });
      // Add the file to the formData
      formData.append("file", file);

      try {
        // Post the file to S3
        await axios.post(url, formData);
      } catch (err) {
        console.log(err);
        setLoading(false);
        return;
      }

      try {
        // Make a request to parse the file
        await API.post("backend", "/parse", {
          body: { key: fields.key }
        });
        setParsed(true);
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    };

    request();
  }, [file]);

  return (
    <>
      <ListItem>
        <ListItemIcon>
          <AttachFile />
        </ListItemIcon>
        <ListItemText primary={file.name} />
        <ListItemSecondaryAction style={{ display: "flex" }}>
          {loading ? (
            <CircularProgress size={30} />
          ) : parsed ? (
            <CheckCircle fontSize="large" style={{ color: green[500] }} />
          ) : (
            <Cancel fontSize="large" style={{ color: red[500] }} />
          )}
        </ListItemSecondaryAction>
      </ListItem>
      <Divider />
    </>
  );
};

export default File;
