import React, { useState, useContext, useEffect } from "react";
import { API } from "aws-amplify";
import Papa from "papaparse";
import xlsx from 'xlsx'
import FileSaver from 'file-saver';

import { makeStyles } from "@material-ui/core/styles";
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Input,
  InputAdornment,
  Button,
  CircularProgress,
  Grow
} from "@material-ui/core";
import { ExpandMore, Search, ClearAll, GetApp } from "@material-ui/icons";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { Autocomplete } from "@material-ui/lab";
import { red } from "@material-ui/core/colors";

import { SearchContext } from "./index";
import { AppContext } from "../App";

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 1200
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 175
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightBold
  },
  panelDetails: {
    flexWrap: "wrap"
  },
  button: {
    margin: theme.spacing(2, 1, 0, 0)
  },
  error: {
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
    "&:hover": {
      backgroundColor: red[700]
    }
  }
}));

const defaultValues = {
  type: "",
  date_from: null,
  date_to: null,
  price_from: "",
  price_to: "",
  location: {},
  registration: "",
  sheet: "",
  plan: "",
  plot: "",
  plot_from: "",
  plot_to: ""
};

const SearchForm = props => {
  const [values, setValues] = useState(defaultValues);
  const appContext = useContext(AppContext);
  const { locations } = appContext;
  const searchContext = useContext(SearchContext);
  const { searching, setSearching, setSearched, sales, setSales } = searchContext;
  const [dateFromError, setDateFromError] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [executing, setExecuting] = useState(false);
  const [districts, setDistricts] = useState([]);
  const [towns, setTowns] = useState([]);
  const [quarters, setQuarters] = useState([]);

  const classes = useStyles();

  useEffect(() => {
    setDistricts(
      locations
        .reduce((districts, location) => {
          const { district_id, district } = location;

          const isAdded = districts.find(
            addedDistrict =>
              addedDistrict.district_id === district_id &&
              addedDistrict.district === district
          );

          if (!isAdded) districts.push({ district_id, district });
          return districts;
        }, [])
        .sort((a, b) => a.district.localeCompare(b.district))
    );
  }, [locations]);

  useEffect(() => {
    if (!(values.location.district_id > 0)) return;

    const towns = locations
      .reduce((towns, location) => {
        const { district_id, district, town_id, town } = location;

        const isAdded = towns.find(
          addedTown => addedTown.town_id === town_id && addedTown.town === town
        );

        if (!isAdded && district_id === values.location.district_id)
          towns.push({ district_id, district, town_id, town });
        return towns;
      }, [])
      .sort((a, b) => a.town.localeCompare(b.town));

    setTowns(towns);
  }, [locations, values.location.district_id]);

  useEffect(() => {
    if (!(values.location.town_id > 0)) {
      setQuarters([]);
      return;
    }

    const quarters = locations
      .reduce((quarters, location) => {
        if (!(location.quarter_id > 0)) return quarters;

        const isAdded = quarters.find(
          addedQuarter =>
            addedQuarter.quarter_id === location.quarter_id &&
            addedQuarter.quarter === location.quarter
        );

        if (
          !isAdded &&
          location.district_id === values.location.district_id &&
          location.town_id === values.location.town_id
        )
          quarters.push(location);
        return quarters;
      }, [])
      .sort((a, b) => a.quarter.localeCompare(b.quarter));

    setQuarters(quarters);
  }, [locations, values.location.town_id, values.location.district_id]);

  const onCancel = () => {
    setSearching(false);
  };

  const onSearch = async () => {
    if (searching) return;

    // Remove null values
    const body = Object.keys(values).reduce((body, column) => {
      if (values[column] !== "") body[column] = values[column];
      return body;
    }, {});

    if (!values.date_from) {
      setDateFromError(true);
      return;
    }

    setSearching(true);

    if (body.date_from) body.date_from = body.date_from.format("YYYY-MM-DD");
    if (body.date_to) {
      body.date_to = body.date_to.format("YYYY-MM-DD");
    } else {
      delete body["date_to"];
    }

    try {
      const query = await API.post("backend", "/search", { body });
      const { QueryExecutionId } = query;

      const interval = setInterval(async () => {
        if (downloading) return;

        setExecuting(true);

        const results = await API.post("backend", "/results", {
          body: { QueryExecutionId }
        });
        const { status, url } = results;

        if (status === "SUCCEEDED") {
          clearInterval(interval);
          setExecuting(false);
          setDownloading(true);

          await Papa.parse(url, {
            download: true,
            header: true,
            complete: sales => {
              setSearching(false);
              setDownloading(false);
              setSearched(true);
              setSales(sales.data.filter(item => !!item.uid));
            }
          });
        } else if (status === "FAILED") {
          clearInterval(interval);
          alert("Failed to search.");
          setSearching(false);
          setExecuting(false);
        }
      }, 5000);
    } catch (err) {
      alert("Failed to search.");
      console.log(err);
      setSearching(false);
      setExecuting(false);
      setDownloading(false);
    }
  };

  return (
    <div className={classes.root}>
      <ExpansionPanel defaultExpanded>
        <ExpansionPanelSummary expandIcon={<ExpandMore />}>
          <Typography className={classes.heading}>Details</Typography>
        </ExpansionPanelSummary>

        <ExpansionPanelDetails className={classes.panelDetails}>
          <FormControl className={classes.formControl}>
            <InputLabel shrink>Sale type</InputLabel>
            <Select
              displayEmpty
              value={values.type}
              onChange={e => setValues({ ...values, type: e.target.value })}
            >
              <MenuItem value="">
                <em>Any</em>
              </MenuItem>
              <MenuItem value="Sales">Sale</MenuItem>
              <MenuItem value="COS">Contract of sale</MenuItem>
            </Select>
          </FormControl>

          <KeyboardDatePicker
            className={classes.formControl}
            error={dateFromError}
            helperText={dateFromError ? "Date from is required" : ""}
            required
            label="Sale date from"
            format="DD/MM/YYYY"
            InputLabelProps={{
              shrink: true
            }}
            value={values.date_from}
            onChange={date_from => {
              setDateFromError(!date_from);
              setValues({ ...values, date_from });
            }}
          />

          <KeyboardDatePicker
            className={classes.formControl}
            label="Sale date to"
            format="DD/MM/YYYY"
            InputLabelProps={{
              shrink: true
            }}
            value={values.date_to}
            onChange={date_to => setValues({ ...values, date_to })}
          />

          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="price-from">Accepted price from</InputLabel>
            <Input
              id="price-from"
              type="number"
              inputProps={{ min: 0 }}
              value={values.price_from}
              onKeyPress={e => {
                if (!/\d$/.test(e.key)) {
                  e.preventDefault();
                }
              }}
              onChange={e =>
                setValues({ ...values, price_from: e.target.value })
              }
              startAdornment={
                <InputAdornment position="start">€</InputAdornment>
              }
            />
          </FormControl>

          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="price-from">Accepted price to</InputLabel>
            <Input
              id="price-from"
              type="number"
              inputProps={{ min: 0 }}
              value={values.price_to}
              onKeyPress={e => {
                if (!/\d$/.test(e.key)) {
                  e.preventDefault();
                }
              }}
              onChange={e => setValues({ ...values, price_to: e.target.value })}
              startAdornment={
                <InputAdornment position="start">€</InputAdornment>
              }
            />
          </FormControl>
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <ExpansionPanel>
        <ExpansionPanelSummary expandIcon={<ExpandMore />}>
          <Typography className={classes.heading}>Location</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.panelDetails}>
          <Autocomplete
            className={classes.formControl}
            getOptionLabel={option => (option.district ? option.district : "")}
            options={districts}
            loading={districts.length === 0}
            value={values.location}
            onChange={(e, location) =>
              setValues({ ...values, location: location ? location : {} })
            }
            renderInput={params => (
              <TextField
                {...params}
                fullWidth
                label="District"
                InputLabelProps={{
                  shrink: true
                }}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {districts.length === 0 ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  )
                }}
              />
            )}
          />

          <Autocomplete
            style={{ minWidth: 230 }}
            className={classes.formControl}
            getOptionLabel={option => (option.town ? option.town : "")}
            options={towns}
            disabled={!(values.location.district_id > 0)}
            value={values.location}
            onChange={(e, location) => {
              if (location) {
                setValues({ ...values, location });
              } else {
                const { district_id, district } = values.location;
                setValues({ ...values, location: { district_id, district } });
              }
            }}
            renderInput={params => (
              <TextField
                {...params}
                fullWidth
                label="Town/Area"
                InputLabelProps={{
                  shrink: true
                }}
              />
            )}
          />

          <Grow in={quarters.length > 0}>
            <Autocomplete
              style={{ minWidth: 230 }}
              className={classes.formControl}
              getOptionLabel={option => (option.quarter ? option.quarter : "")}
              options={quarters}
              value={values.location}
              onChange={(e, location) => {
                if (location) {
                  setValues({ ...values, location });
                } else {
                  const {
                    district_id,
                    district,
                    town_id,
                    town
                  } = values.location;
                  setValues({
                    ...values,
                    location: { district_id, district, town_id, town }
                  });
                }
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  fullWidth
                  label="Quarter"
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              )}
            />
          </Grow>
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <ExpansionPanel>
        <ExpansionPanelSummary expandIcon={<ExpandMore />}>
          <Typography className={classes.heading}>Additional</Typography>
        </ExpansionPanelSummary>

        <ExpansionPanelDetails className={classes.panelDetails}>
          <TextField
            label="Registration #"
            type="number"
            inputProps={{ min: 0 }}
            className={classes.formControl}
            value={values.registration}
            InputLabelProps={{
              shrink: true
            }}
            onKeyPress={e => {
              if (!/\d$/.test(e.key)) {
                e.preventDefault();
              }
            }}
            onChange={e =>
              setValues({ ...values, registration: e.target.value })
            }
          />

          <TextField
            label="Sheet"
            type="number"
            inputProps={{ min: 0 }}
            className={classes.formControl}
            value={values.sheet}
            InputLabelProps={{
              shrink: true
            }}
            onKeyPress={e => {
              if (!/\d$/.test(e.key)) {
                e.preventDefault();
              }
            }}
            onChange={e => setValues({ ...values, sheet: e.target.value })}
          />

          <TextField
            label="Plan"
            type="text"
            className={classes.formControl}
            value={values.plan}
            InputLabelProps={{
              shrink: true
            }}
            onChange={e => setValues({ ...values, plan: e.target.value })}
          />

          <TextField
            label="Plot #"
            type="number"
            inputProps={{ min: 0 }}
            className={classes.formControl}
            value={values.plot}
            InputLabelProps={{
              shrink: true
            }}
            onKeyPress={e => {
              if (!/\d$/.test(e.key)) {
                e.preventDefault();
              }
            }}
            onChange={e => setValues({ ...values, plot: e.target.value })}
          />

          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="plot-from" shrink>
              Plot size from
            </InputLabel>
            <Input
              id="plot-from"
              type="number"
              inputProps={{ min: 0 }}
              value={values.plot_from}
              onKeyPress={e => {
                if (!/\d$/.test(e.key)) {
                  e.preventDefault();
                }
              }}
              onChange={e =>
                setValues({ ...values, plot_from: e.target.value })
              }
              endAdornment={<InputAdornment position="end">m²</InputAdornment>}
            />
          </FormControl>

          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="plot-from" shrink>
              Plot size to
            </InputLabel>
            <Input
              id="plot-to"
              type="number"
              inputProps={{ min: 0 }}
              value={values.plot_to}
              onKeyPress={e => {
                if (!/\d$/.test(e.key)) {
                  e.preventDefault();
                }
              }}
              onChange={e => setValues({ ...values, plot_to: e.target.value })}
              endAdornment={<InputAdornment position="end">m²</InputAdornment>}
            />
          </FormControl>
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <Button
        variant="outlined"
        className={classes.button}
        startIcon={<ClearAll />}
        onClick={() => setValues(defaultValues)}
      >
        Clear filters
      </Button>

      <Button
        disableElevation
        variant="contained"
        color="primary"
        className={classes.button}
        startIcon={
          searching ? (
            <CircularProgress color="inherit" size={20} />
          ) : (
            <Search />
          )
        }
        onClick={onSearch}
      >
        {searching && !executing & !downloading ? "Submitting query..." : ""}
        {executing && "Running query..."}
        {downloading && "Downloading results..."}
        {!searching && !executing && !downloading && "Search"}
      </Button>

      {sales.length > 0 && (
        <Button
          disableElevation
          variant="outlined"
          color="secondary"
          className={classes.button}
          startIcon={<GetApp />}
          onClick={() => {
            const results = xlsx.utils.json_to_sheet(sales)
            const wb = xlsx.utils.book_new();
            xlsx.utils.book_append_sheet(wb, results, "results");
            const wbOut = xlsx.write(wb, { type: "array", bookType: "xlsx" });
            const blob = new Blob([wbOut], { type: "application/binary" });
            FileSaver.saveAs(blob, "results.xlsx");
          }}
        >
          Download
        </Button>
      )}

      {/* {searching && (
        <Button
          disableElevation
          variant="contained"
          className={`${classes.button} ${classes.error}`}
          startIcon={<Close />}
          onClick={onCancel}
        >
          Cancel query
        </Button>
      )} */}
    </div>
  );
};

export default SearchForm;
